<template>
  <div class="w-full flex flex-wrap justify-between items-center">
    <label class="text-xs mb-1.5 text-[#858C91] font-medium" v-if="label">{{ label }}</label>
    <slot name="hint" />
    <div
      class="bg-[#FCFDFE] border-[#F0F1F7] w-full h-10 rounded-lg flex items-center border"
      :class="{ 'border-2 border-red-500': invalid || errorText }"
    >
      <select
        class="h-full bg-transparent border-0 text-sm w-full py-0.5 pl-2.5 pr-4"
        :value="modelValue"
        @change="(event) => emit('update:modelValue', event.target.value)"
        :required="required"
      >
        <option
          v-for="item in options"
          :value="item.id"
          :hidden="item.hidden"
        >
          {{ item.name }}
        </option>
      </select>
    </div>
    <p class="text-xs text-red-500">{{ errorText }}</p>
  </div>
</template>

<script setup >

const props = defineProps({
  modelValue: {
    type: [String, Number]
  },
  options: {
    type: Array,
    default: () => []
  },
  label: {
    type: String,
    default: ''
  },
  invalid: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: ''
  },
  pattern: {
    type: String,
    default: null
  },
  errorText: {
    type: String,
    default: null
  },
  required: {
    type: [Boolean, String],
    default: null
  },
})

const emit = defineEmits(['update:modelValue'])

</script>
